import axios from "axios";

import { quickbooks_invoice_endpoint, quickbooks_create_invoice_woocommerce_endpoint, create_password, quickbooks_create_customer_endpoint } from "./../../constant/api-endpoints";

// Create Invoice
let createInvoice = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(quickbooks_invoice_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

// Create Invoice - Woocommerce
let createInvoiceWoocommerce = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(quickbooks_create_invoice_woocommerce_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((e) => {
                return reject(e.response);
            });
    });
};

// Create Customer
let createCustomer = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(quickbooks_create_customer_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

// Get Invoice
let getInvoice = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(quickbooks_invoice_endpoint + "?invoiceId=" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

// For DEV
let createPassword = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(create_password + "/" + id)
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

// Get Customer Id
// let getCustomerId = function(name) {
//     return new Promise((resolve, reject) => {
//         axios
//             .get(quickbooks_customer_endpoint + "/name/" + name, {
//                 headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
//             })
//             .then((res) => {
//                 return resolve(res.data);
//             })
//             .catch((err) => {
//                 return reject(err.response);
//             });
//     });
// };

export default { createInvoice, createPassword, createInvoiceWoocommerce, getInvoice, createCustomer };
